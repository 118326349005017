import MessageMap from "../i18n";

const es419: MessageMap = {
  RESTRICTION_ATTRIBUTES: {
    TYPE: "Tipo",
    KEY: "Clave",
    CASHBACK: "Cashback",
    ONBOARDING: "Onboarding",
    LAST_EDIT: "Última edición",
    EDIT_BY: "Editador por",
    EDIT: "Editar",
    TITLE: "Lista de restricciones",
    ANTIFRAUD: "Antifraude",
    COUNTRY_OF_RESTRICTION: "País de restricción",
    COUNTRY: "País",
    NEW_RESTRICTION: "Nueva restricción",
    PLACEHOLDER_SEARCH: "Clave de restricción de búsqueda",
    ZE_COMPENSA: "Zé Compensa",
    CLUB_TADA: "Club TaDa",
    NEW_KEY_RESTRICTION: "Nueva clave de restricción ",
    RESTRICTION_DETAILS: "Detalles de restricción",
    KEY_TYPE: "Tipo de clave",
    KEY_TYPE_PLACEHOLDER: "Elige el tipo de restricción",
    KEY_TO_RESTRICT: "Clave para restringir",
    KEY_TO_RESTRICT_PLACEHOLDER: `Por ejemplo: "restringir.usuario@gmail.com"`,
    KIND_OF_RESTRICTION: "Tipo de restricción",
    RESTRICTION_REASON: "Motivo de la restricción",
    RESTRICTION_REASON_PLACEHOLDER: "Elige el motivo de la restricción",
    CONFIRM_THE_UPDATE_FOR_RESTRICTION:
      "Confirma la actualización para restricciones",
    LOCKED: "Bloqueado",
    UNLOCKED: "Desbloqueado",
    EDIT_RESTRICTION_KEY: "Editar una clave de restricción",
  },
  EDIT_RESTRICTION: {
    DETAILS_OF_RESTRICTION: "Detalles de restricción",
    TYPE_OF_KEY: "Tipo de clave",
    KEY_OF_RESTRICTION: "Clave restringida",
    COUNTRY_OF_RESTRICTION: "País de restricción",
    TYPE_OF_RESTRICTION: "Tipo de restricción",
    REASON_OF_RESTRICTION: "Motivo de la restricción",
    UPDATE_RESTRICTION: "Actualizar restricción",
  },
  RESTRICTION_HISTORY: {
    TITLE: "Historial de restricciones",
    ONBOARDING_LOCKED: "Onboarding - Bloqueado",
    ONBOARDING_UNLOCKED: "Onboarding - Desbloqueado",
    RESTRICTION_UPDATED: "Restricción modificada",
    RESTRICTION_CREATED: "Restricción creada",
  },
  RESTRICTION_TABLE: {
    DOCUMENT_TYPE: {
      HN: "RTN",
      BR: "CPF",
      SV: "NIT o DUI",
      PA: "RUC",
      BO: "NIT",
      PY: "RUC",
      PE: "DNI o RUC",
      EC: "RUC",
      DO: "Cédula",
      CO: "NIT",
      MX: "RFC",
      ZA: "TIN",
      AR: "DNI",
    },
    TYPE: {
      DOCUMENT: "Identificación del documento",
      EMAIL: "Correo electrónico",
      PHONE_NUMBER: "Número de teléfono",
      DEVICE_ID: "Dispositivo",
      DEVICE_AND_EMAIL: "Dispositivo y correo electrónico",
    },
    LOADING: {
      TITLE: "Cargando",
      MESSAGE: "Estamos preparando la lista de restricciones para usted.",
    },
    EMPTY: {
      TITLE: "Ups...",
      MESSAGE: "No recibimos ningún dato de este país.",
    },
    FILTERED: {
      TITLE: "Aún no hay restricciones",
      MESSAGE: "Las restricciones se mostrarán aquí.",
    },
  },
  BUTTONS: {
    EDIT: "Editar",
  },
  TEST: {
    TRANSLATE: "Translate",
    MISSING: "Only English option available",
  },
  SIDEMENU: {
    TITLE: "Home",
  },
  ACTIONS: {
    GO_BACK: "Volver atrás",
    BACK: "Volver",
    CANCEL: "Cancelar",
    CONFIRM: "Confirmar",
    SAVE: "Guardar",
    SEARCH: "Buscar",
    APPLY: "Aplicar",
    COPY: "Copiar",
    EDIT: "Editar",
    SELECT: {
      DEFAULT: "Seleccionar",
      ALL: "Seleccionar todo",
      ONE_OR_MORE: "Seleccione uno o más",
    },
    REMOVE: "Remove",
    YES: "Sí",
    NO: "No",
    SHOW_DETAILS: "Mostrar detalles",
    CREATE: "Crear",
    CONTINUE: "Continuar",
    PUBLISH: "Publicar",
    TRY_AGAIN: "Inténtalo de nuevo",
    CONFIRM_RESTRICTION: "Confirmar restricción",
  },
  COUNTRIES: {
    HN: "Honduras",
    SV: "El Salvador",
    BO: "Bolivia",
    PA: "Panamá",
    DO: "República Dominicana",
    PY: "Paraguay",
    PE: "Perú",
    EC: "Ecuador",
    ZA: "Sudáfrica",
    CO: "Colombia",
    MX: "México",
    AR: "Argentina",
    BR: "Brasil",
  },
  UNAUTHORIZED_PAGE: {
    TITLE: "Ups, acceso denegado.",
    DESCRIPTION: "Parece que no tienes permiso para acceder a esta página.",
    ACTION: "Volver a la página de inicio",
  },
  PAGINATION: {
    QUANTITY_INDICATOR: "de",
    PAGE_SIZE_OPTIONS: "Artículos por página: {options}",
  },
  ERROR: {
    400: {
      TITLE: "Ups, algo falló",
      MESSAGE: "Un error inesperado ocurrió. Por favor, vuelve a intentar.",
    },
    401: {
      TITLE: "Ups, algo falló",
      MESSAGE:
        "Entra en contacto con nuestro equipo de soporte para revisar los ajustes de tu cuenta.",
    },
    403: {
      TITLE: "Ups, algo falló",
      MESSAGE:
        "Entra en contacto con nuestro equipo de soporte para revisar los ajustes de tu cuenta.",
    },
    404: {
      TITLE: "Ups, algo falló",
      MESSAGE:
        "No pudimos llevar a cabo esta acción. Por favor, vuelve a intentar.",
    },
    500: {
      TITLE: "Ups, algo falló",
      MESSAGE: "Un error inesperado ocurrió. Por favor, vuelve a intentar.",
    },
    502: {
      TITLE: "Ups, algo falló",
      MESSAGE: "Un error inesperado ocurrió. Por favor, vuelve a intentar.",
    },
    503: {
      TITLE: "Ups, algo falló",
      MESSAGE: "Un error inesperado ocurrió. Por favor, vuelve a intentar.",
    },
  },
  FIELDS: {
    OPTIONAL: "Opcional",
    REQUIRED: {
      GENERIC: "Campo requerido",
    },
    FORMAT: {
      GENERIC: "Formato inválido",
    },
    VALIDATION: {
      DUPLICATED: "Esta clave ya está restringida.",
    },
  },
  VERSION: {
    VERSION_APP: "Versión",
    SERVER: "Servidor",
    RIGHTS_RESERVED:
      "©2023. Anheuser-Busch InBev S.A. Todos los derechos reservados.",
  },
};

export default es419;
