import { EnvConfig } from "@/components/EnvProvider";
import PERMISSION_GROUPS from "@/config/permissions";
import { useAuthenticationService, useHasPermission } from "admin-portal-shared-services";
import { useFilterStore } from "../stores/restriction/useFilterStore";

export const getInitialCountry = (props: Readonly<EnvConfig>) => {
  const authentication = useAuthenticationService();
  const hasPermission = useHasPermission(PERMISSION_GROUPS.RESTRICTION_ADMIN);

  const restrictionFilterValues = localStorage.getItem('restrictionFilterValues');

  const { state: { country, searchType } } = JSON.parse(restrictionFilterValues)

  let initialCountry = "";
  if (hasPermission) {
    initialCountry = props.SUPPORTED_COUNTRIES[0];
  } else {
    const userSupportedCountries = authentication.getSupportedCountries();
    const filteredCountries = props.SUPPORTED_COUNTRIES.filter((country) =>
      userSupportedCountries.includes(country),
    );
    initialCountry = filteredCountries[0];
  }

  const setCountry = useFilterStore((state) => state.setCountry);
  const setSearchType = useFilterStore((state) => state.setSearchType);
  setCountry(country || initialCountry);
  setSearchType(searchType);

  return country || initialCountry;
};
