import MessageMap from "../i18n";

const ptBR: MessageMap = {
  RESTRICTION_ATTRIBUTES: {
    TYPE: "Tipo",
    KEY: "Chave",
    CASHBACK: "Cashback",
    ONBOARDING: "Onboarding",
    LAST_EDIT: "Última edição",
    EDIT_BY: "Editador por",
    EDIT: "Editar",
    TITLE: "Lista de restrição",
    ANTIFRAUD: "Antifraude",
    COUNTRY_OF_RESTRICTION: "País da restrição",
    COUNTRY: "País",
    NEW_RESTRICTION: "Nova restrição",
    PLACEHOLDER_SEARCH: "Buscar chave da restrição",
    ZE_COMPENSA: "Zé Compensa",
    CLUB_TADA: "Club TaDa",
    NEW_KEY_RESTRICTION: "Nova chave de restrição",
    RESTRICTION_DETAILS: "Detalhes da restrição",
    KEY_TYPE: "Tipo de chave",
    KEY_TYPE_PLACEHOLDER: "Escolha o tipo de restrição",
    KEY_TO_RESTRICT: "Chave para restringir",
    KEY_TO_RESTRICT_PLACEHOLDER: `Ex: "restrict.user@gmail.com”`,
    KIND_OF_RESTRICTION: "Tipo de restrição",
    RESTRICTION_REASON: "Motivo da restrição",
    RESTRICTION_REASON_PLACEHOLDER: "Escolha o motivo da restrição",
    CONFIRM_THE_UPDATE_FOR_RESTRICTION:
      "Confirme a atualização para restrições",
    LOCKED: "Bloqueado",
    UNLOCKED: "Desbloqueado",
    EDIT_RESTRICTION_KEY: "Editar uma chave de restrição",
  },
  EDIT_RESTRICTION: {
    DETAILS_OF_RESTRICTION: "Detalhes da restrição",
    TYPE_OF_KEY: "Tipo de chave",
    KEY_OF_RESTRICTION: "Chave de restrição",
    COUNTRY_OF_RESTRICTION: "País da restrição",
    TYPE_OF_RESTRICTION: "Tipo de restrição",
    REASON_OF_RESTRICTION: "Motivo da restrição",
    UPDATE_RESTRICTION: "Atualizar restrição",
  },
  RESTRICTION_HISTORY: {
    TITLE: "Histórico de restrição",
    ONBOARDING_LOCKED: "Onboarding - Bloqueado",
    ONBOARDING_UNLOCKED: "Onboarding - Desbloqueado",
    RESTRICTION_UPDATED: "Restrição modificada",
    RESTRICTION_CREATED: "Restrição criada",
  },
  RESTRICTION_TABLE: {
    DOCUMENT_TYPE: {
      HN: "RTN",
      BR: "CPF",
      SV: "NIT ou DUI",
      PA: "RUC",
      BO: "NIT",
      PY: "RUC",
      PE: "DNI ou RUC",
      EC: "RUC",
      DO: "Cédula",
      CO: "NIT",
      MX: "RFC",
      ZA: "TIN",
      AR: "DNI",
    },
    TYPE: {
      DOCUMENT: "CPF",
      EMAIL: "E-mail",
      PHONE_NUMBER: "Telefone",
      DEVICE_ID: "Dispositivo",
      DEVICE_AND_EMAIL: "Dispositivo e e-mail",
    },
    LOADING: {
      TITLE: "Carregando",
      MESSAGE: "Estamos preparando a lista de restrições para você.",
    },
    EMPTY: {
      TITLE: "Opa...",
      MESSAGE: "Não recebemos nenhum dado deste país.",
    },
    FILTERED: {
      TITLE: "Ainda sem restrição",
      MESSAGE: "A restrição será exibida aqui.",
    },
  },
  BUTTONS: {
    EDIT: "Editar",
  },
  TEST: {
    TRANSLATE: "Translate",
    MISSING: "Only English option available",
  },
  SIDEMENU: {
    TITLE: "Home",
  },
  ACTIONS: {
    GO_BACK: "Voltar",
    BACK: "Voltar",
    CANCEL: "Cancelar",
    CONFIRM: "Confirm",
    SAVE: "Salvar",
    SEARCH: "Busca",
    APPLY: "Aplicar",
    COPY: "Copiar",
    EDIT: "Editar",
    SELECT: {
      DEFAULT: "Selecione",
      ALL: "Selecionar todos",
      ONE_OR_MORE: "Selecione um ou mais",
    },
    REMOVE: "Remover",
    YES: "Sim",
    NO: "Não",
    SHOW_DETAILS: "Mostrar Detalhes",
    CREATE: "Criar",
    CONTINUE: "Continuar",
    PUBLISH: "Publicar",
    TRY_AGAIN: "Tente novamente",
    CONFIRM_RESTRICTION: "Confirmar restrição",
  },
  COUNTRIES: {
    HN: "Honduras",
    SV: "El Salvador",
    PA: "Panamá",
    BO: "Bolívia",
    DO: "Rública Dominicana",
    PY: "Paraguai",
    PE: "Peru",
    EC: "Equador",
    ZA: "África do Sul",
    CO: "Colombia",
    MX: "México",
    AR: "Argentina",
    BR: "Brasil",
  },
  UNAUTHORIZED_PAGE: {
    TITLE: "Oops, access denied!",
    DESCRIPTION: "It seems you don't have permission to access this page.",
    ACTION: "Go back to the homepage",
  },
  PAGINATION: {
    QUANTITY_INDICATOR: "de",
    PAGE_SIZE_OPTIONS: "Itens por página: {options}",
  },
  ERROR: {
    400: {
      TITLE: "Oops, something went wrong!",
      MESSAGE: "An unexpected error ocurred. Please try again.",
    },
    401: {
      TITLE: "Oops, something went wrong!",
      MESSAGE: "Contact our support team to review your account settings.",
    },
    403: {
      TITLE: "Oops, something went wrong!",
      MESSAGE: "Contact our support team to review your account settings.",
    },
    404: {
      TITLE: "Oops, something went wrong!",
      MESSAGE: "We couldn't perform this action. Try again.",
    },
    500: {
      TITLE: "Oops, something went wrong!",
      MESSAGE: "An unexpected error ocurred. Please try again.",
    },
    502: {
      TITLE: "Oops, something went wrong!",
      MESSAGE: "An unexpected error ocurred. Please try again.",
    },
    503: {
      TITLE: "Oops, something went wrong!",
      MESSAGE: "An unexpected error ocurred. Please try again.",
    },
  },
  FIELDS: {
    OPTIONAL: "Opcional",
    REQUIRED: {
      GENERIC: "Campo obrigatório",
    },
    FORMAT: {
      GENERIC: "Formato inválido",
    },
    VALIDATION: {
      DUPLICATED: "Esta chave já está restrita.",
    },
  },
  VERSION: {
    VERSION_APP: "Versão",
    SERVER: "Servidor",
    RIGHTS_RESERVED:
      "©2023. Anheuser-Busch Inbev S.A. Todos os direitos reservados",
  },
};

export default ptBR;
